<template>
  <b-card title="Brime Developer SDKs">
    <b-card-text>Stay tuned.</b-card-text>
  </b-card>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
}
</script>

<style>

</style>
